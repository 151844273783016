export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94')
];

export const server_loads = [];

export const dictionary = {
		"/(app)": [35,[2]],
		"/(app)/admin": [36,[2,3]],
		"/(app)/admin/data-sync": [37,[2,3]],
		"/(app)/admin/docket-types": [38,[2,3,4]],
		"/(app)/admin/docket-types/[id]": [39,[2,3,4]],
		"/(app)/admin/filing-types": [40,[2,3,5]],
		"/(app)/admin/filing-types/[id]": [41,[2,3,5]],
		"/(app)/admin/hearing-types": [42,[2,3,6]],
		"/(app)/admin/hearing-types/[id]": [43,[2,3,6]],
		"/(app)/admin/lovs": [44,[2,3,7]],
		"/(app)/admin/lovs/[id]": [45,[2,3,7]],
		"/(app)/admin/organizations": [46,[2,3,8]],
		"/(app)/admin/organizations/[id]": [47,[2,3,8]],
		"/(app)/admin/permissions": [48,[2,3,9]],
		"/(app)/admin/text-templates": [49,[2,3,10]],
		"/(app)/admin/text-templates/[id]": [50,[2,3,10]],
		"/(app)/admin/users": [51,[2,3,11]],
		"/(app)/admin/users/[id]": [52,[2,3,11]],
		"/(app)/admin/users/[id]/positions/[positionId]": [53,[2,3,11,12]],
		"/(app)/admin/workflows": [54,[2,3,13]],
		"/(app)/admin/workflows/[id]": [55,[2,3,13]],
		"/(app)/case-management": [56,[2,14]],
		"/(app)/case-management/assignments": [65,[2,14]],
		"/(app)/case-management/costs": [66,[2,14,16]],
		"/(app)/case-management/new-case": [67,[2,14,17]],
		"/(app)/case-management/scheduling": [68,[2,14,18]],
		"/(app)/case-management/scheduling/sessions/[id]": [69,[2,14,18,19]],
		"/(app)/case-management/[id]": [57,[2,14,15]],
		"/(app)/case-management/[id]/claims/[claimId]": [58,[2,14,15]],
		"/(app)/case-management/[id]/costs/[costId]": [59,[2,14,15]],
		"/(app)/case-management/[id]/filings/new": [61,[2,14,15]],
		"/(app)/case-management/[id]/filings/[filingId]": [60,[2,14,15]],
		"/(app)/case-management/[id]/judgments/[judgmentId]": [62,[2,14,15]],
		"/(app)/case-management/[id]/participants/[participantId]": [63,[2,14,15]],
		"/(app)/case-management/[id]/service-records/[serviceRecordId]": [64,[2,14,15]],
		"/(public)/confirm-email": [91,[34]],
		"/(app)/financial": [70,[2,20]],
		"/(app)/financial/accounts": [71,[2,20,21]],
		"/(app)/financial/cost-schedule": [72,[2,20]],
		"/(app)/financial/disbursements": [73,[2,20,22]],
		"/(app)/financial/disbursements/batches": [75,[2,20,22]],
		"/(app)/financial/disbursements/disbursed": [76,[2,20,22]],
		"/(app)/financial/disbursements/groups": [77,[2,20,22]],
		"/(app)/financial/disbursements/reconciliation": [78,[2,20,22]],
		"/(app)/financial/disbursements/reconciliation/[id]": [79,[2,20,22,24]],
		"/(app)/financial/disbursements/undisbursed": [80,[2,20,22]],
		"/(app)/financial/disbursements/[id]": [74,[2,20,22,23]],
		"/(app)/financial/receipt-sessions": [81,[2,20,25]],
		"/(app)/financial/receipt-sessions/[id]": [82,[2,20,25]],
		"/(app)/financial/receipts": [83,[2,20,26]],
		"/(app)/financial/receipts/new": [85,[2,20,26,28]],
		"/(app)/financial/receipts/[id]": [84,[2,20,26,27]],
		"/(app)/financial/reports": [86,[2,20,29]],
		"/(app)/financial/transactions": [87,[2,20,30]],
		"/(app)/financial/transactions/[id]": [88,[2,20,30,31]],
		"/(public)/forgot-password": [92,[34]],
		"/(public)/login": [93,[34]],
		"/(public)/reset-password": [94,[34]],
		"/(app)/support/agent": [89,[2,32]],
		"/(app)/support/report-instances": [90,[2,33]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
	
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';